export const data = {
  panelImageName: "terceiro_setor-bg.png",
  divisorText: "Entidades Sem Fins Lucrativos",
  content: {
    imageName: "TerceiroSetor.jpg",
    title: "Como ser nosso cliente?",
    text:
      "Análise do segmento da entidade no terceiro setor e orientações completas para regularizar todas as atividades da entidade.",
    options: [
      "Análise de Estatutos e Atas",
      "Assessoria Tributária",
      "Assessoria Financeira",
      "Atendimento a Fiscalizações municipais, previdenciárias e federais",
    ],
    contactText:
      "Tenha uma contabilidade personalizada, cadastre-se e receba nosso contato",
  },
}
